'use client';

import React, { FC, useEffect } from 'react'
import { usePathname, useSearchParams } from 'next/navigation';
import { useAnalytics } from '#/app/_hooks/useSegment';
import { useSupabase } from '#/app/_hooks/useSupabase';

// See: https://nextjs.org/docs/app/api-reference/functions/use-router#router-events
export const NavigationEvents: FC<{}> = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams()
  const analytics = useAnalytics();
  const { session } = useSupabase();

  // Heads up: on localhost (in react dev mode), effects are called twice
  // this might emit two events
  useEffect(() => {
    const url = `${pathname}?${searchParams}`
    analytics.page();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  // Add an additional identify() call just in case...
  // useEffect(() => {
  //   if (session?.user.id && session?.user.email) {
  //     analytics.identify(session.user.id, {
  //       email: session.user.email,
  //     });
  //   }
  // }, [analytics, session?.user.id, session?.user.email])

  return null;
}
