'use client';

import {FC, PropsWithChildren} from "react";
import clsx from "clsx";
import {Menu} from '@headlessui/react'
import Link from "next/link";
import NavDropdown from "#/app/_components/Nav/NavDropdown";

type MenuItem = {
  name: string;
  href: string;
  external?: boolean;
}

const MENU_ITEMS: MenuItem[] = [
  // {
  //   name: "Support",
  //   href: NOTION_SUPPORT_URL,
  //   external: true
  // },
  {
    name: "Settings",
    href: "/settings"
  },
  {
    name: "Sign Out",
    href: "/signout"
  }
]

const AccountDropdown: FC<PropsWithChildren<{ email: string }>> = ({
 email
}) => {
  return (
    <NavDropdown buttonLabel={email} openMethod="hover">
      <div className="py-1">
        {/*<p className="pl-4 text-sm my-2 text-secondary">{email}</p>*/}
        {MENU_ITEMS.map((item, index) => (
          <Menu.Item key={index}>
            {({active}) => (
              <Link
                href={item.href}
                className={clsx(
                  active ? 'bg-gray-800 hover:text-gray-300 rounded' : 'text-gray-100',
                  'block px-4 py-2 text-sm'
                )}
                target={item.external ? "_blank" : "_self"}
                rel={item.external ? "noopener noreferrer" : ""}
              >
                {item.name}
              </Link>
            )}
          </Menu.Item>
        ))}
      </div>
    </NavDropdown>
  )
}

export default AccountDropdown;