'use client'

import {createStore, Provider as JotaiProvider} from "jotai"
import {Toaster} from "react-hot-toast";
import SupabaseProvider from "#/app/_providers/Supabase";
import {Session} from "@supabase/supabase-js";
import { SegmentProvider } from "#/app/_providers/Segment";
import { NavigationEvents } from "#/app/_providers/NavigationEvents";

// FIXME: refactor & move somewhere else soon
export const store = createStore();

export default function Providers({
  children,
  session
}: {
  children: React.ReactNode;
  session: Session | null
}) {
  return (
    <SegmentProvider>
      <JotaiProvider store={store}>
        <SupabaseProvider session={session}>
          <Toaster position="bottom-center"/>
          {children}
        </SupabaseProvider>
      </JotaiProvider>
    </SegmentProvider>
  )
}
